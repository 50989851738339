<template>
  <v-container id="crud" fluid tag="section">

      <v-row>
        <v-col>
          <v-expansion-panels v-model="panelAbierto" class="filtros-trans-container">
            <v-expansion-panel  :value="0">
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Filtros
              </v-expansion-panel-header>
              <v-expansion-panel-content >
                <hr>
                <br/>
                <v-row class="">
                  <v-col cols="12" sm="2">
                    <v-text-field
                      v-model="formSearch.ref_pedido"
                      label="Ref pedido"
                      single-line hide-details
                      outlined
                      clearable
                      filled
                      dense
                      rounded
                      class="filtros-trans"
                      @click:append="clearField('ref_pedido')"
                      ></v-text-field>
                  </v-col>
                  <v-col v-if="isAnAd != 1" cols="12" sm="3">
                    <v-select
                      v-model="formSearch.ente"
                      :items="entidades"
                      label="Ente"
                      class="filtros-trans"
                      outlined
                      filled
                      dense
                      rounded
                      :append-icon="formSearch.ente ? 'mdi-close' : 'mdi-menu-down'"
                      @click:append="clearEntidad"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedStartDate"
                          label="Fecha inicio"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="filtros-trans"
                          outlined
                          filled
                          dense
                          rounded
                          @click:append="clearStartDate"
                          :append-icon="startDate ? 'mdi-close' : 'mdi-menu-down'"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        no-title
                        scrollable
                        locale="es"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!--
                  <v-col cols="12" md="6">
                    <v-date-picker
                      v-model="startDate"
                      label="Fecha de inicio"
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-date-picker
                      v-model="endDate"
                      :min="startDate"
                      label="Fecha final"
                    ></v-date-picker>
                  </v-col>
                  -->
                  <v-col cols="12" sm="2">
                    <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition"
                      offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedEndDate"
                          label="Fecha fin"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="filtros-trans"
                          outlined
                          filled
                          dense
                          rounded
                          @click:append="clearEndDate"
                          :append-icon="endDate ? 'mdi-close' : 'mdi-menu-down'"

                          ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="maxDate"
                        :min="startDate"
                        no-title
                        scrollable
                        locale="es"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="2" class="d-flex align-center">
                    <v-btn
                      color="primary"
                      small
                      @click="fetchTransactions()"
                        >Buscar
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <br>
                <br>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <Loading  v-if="isLoading" :isLoading="isLoading" :loadingMsg="loadingMsg" />
      <v-row>
        <v-col cols="auto" md="12" class="">
        <v-data-table
          :headers="showHeaders"
          :items="filteredData"
          :search="search"
          sort-by="secret"
          class="elevation-0"
        >
          <template v-slot:no-data>
            <div v-if="loading" class="text-center">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
            </div>
            <div v-else>
              Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores<v-icon right>mdi-magnify</v-icon>
            </div>
          </template>
          <!--
          <template v-slot:[`header.U_RefPedido`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>

          <template v-slot:[`header.estado`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>

          <template v-slot:[`header.CardName`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>

          <template v-slot:[`header.ente`]="{ header }">
            <filter-component
              v-bind:header="{ header }"
              v-bind:filters="filters"
            ></filter-component>
          </template>
          -->
          <template v-slot:[`item.U_RefPedido`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-center">
                <a
                  href="#"
                  class="text-primary text-decoration-underline"
                  @click.prevent="goToDetalleInModal(item)"
                >
                  {{ item.U_RefPedido }}
                </a>
              </v-col>

            </v-row>
          </template>
          <template v-slot:[`item.estado`]="{ item }">

            <v-row  >
              <v-col >
                <div :class="$getCssTransacciones(item.estado)" class="table-cell-trans text-center pt-2" style="width:140px" >
                  {{ item.estado }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.CardName`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.CardName }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.ente`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.ente }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.DocTotal`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ (item.DocTotal)? '$'+$formatCurrencyValue(item.DocTotal):item.DocTotal }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.U_MPago`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans text-uppercase">
                {{ item.U_MPago }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.DocDate`]="{ item }">
            <v-row>
              <v-col class="table-cell-trans">
                {{ (item.DocDate)? $formatDate(item.DocDate) :''}}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">


                  <v-icon
                    large
                    :color="item.tipo	== 'orders'?'warning':item.tipo	== 'invoices'?'primary':'success'"
                    :title="item.tipo	== 'orders'?'Ver Orden':item.tipo	== 'invoices'?'Ver Factura':'Ver Factura Borrador'"
                    @click="viewFactura(item, item.tipo)"
                    >
                    mdi-file-document

                  </v-icon>
                  <v-icon   v-if="(sePuedeFacturar(item))"
                    large
                    color="success" title="Facturar"
                    @click="facturar(item, item.tipo)"
                   >
                    mdi-cash
                  </v-icon>

                  <v-icon  v-if=" item.estado !=='Anulado' && (esMenorA24Horas(item))"
                    large
                    color="error" title="Anular Transacción"
                    @click="anularTransaccion(item)">
                    mdi-close
                  </v-icon>

          </template>
        </v-data-table>
        <dialog-confirmar
                v-model="dialogs['anularTransaccion']"
                @input="dialog = $event"
                title="¿Estás seguro de cancelar esta transacción?"
                info="Esta acción no se puede deshacer. ¿Deseas continuar?"
                icon="mdi-close"
                color="#F44336"

                @confirm="confirmAnularTransaccion()"
              />
        <dialog-form-completed
                v-model="dialogs['transaccionAnulada']"
                @input="dialog = $event"
                title="Acción completada"
                info="La transacción se cancelo correctamente."
                icon="mdi-check"
                color="#0078D433"
                @confirm="fetchTransactions()"
          />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>


    <v-dialog
      v-model="dialogTS"
      max-width="98%"
      persistent
    >
      <v-card>
        <v-form ref="form"   lazy-validation>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogTS = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
               Detalle de Transacción {{ transaccionSeleccionada.U_RefPedido }}
            </v-toolbar-title>
            <v-spacer></v-spacer>


          </v-toolbar>

          <v-card-text  style="overflow-y: scroll; max-height: 78vh">
            <v-container>
                <div v-if="transaccionSeleccionada && transaccionSeleccionada.DocumentLines.length">
                      <v-card>
                        <v-card-title class="text-h5 font-weight-bold d-flex align-left mb-5">
                          Detalle de Transacción
                        </v-card-title>
                        <v-card-text>
                          <v-row class="mt-4 my-0 py-0">
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Ente</label>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Delegación</label>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Cliente</label>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Fecha de Inicio</label>
                            </v-col>
                          </v-row>
                          <v-row class="mt-1 py-0">
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                v-model="transaccionSeleccionada.ente"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                v-model="transaccionSeleccionada.U_Deleg"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                v-model="transaccionSeleccionada.CardName"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                :value="formattedFechaInicio"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-4 my-0 py-0">
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Método de pago</label>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Ref Pedido</label>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Total</label>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <label class="px-6 mx-4">Estado</label>
                            </v-col>
                          </v-row>
                          <v-row class="mt-1 py-0">
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                v-model="transaccionSeleccionada.U_MPago"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                v-model="transaccionSeleccionada.U_RefPedido"
                                dense
                                outlined
                                disabled
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                :value="formatCurrency(transaccionSeleccionada.DocTotal)"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="my-0 py-0">
                              <v-text-field
                                v-model="transaccionSeleccionada.estado"
                                dense
                                outlined
                                readonly
                                persistent-placeholder
                                class="px-6 mx-4"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>

                      <v-simple-table class="elevation-1 styled-table mt-6">
                        <thead>
                        <tr>
                          <th>Material</th>
                          <th>Cantidad</th>
                          <th>Precio</th>
                          <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in transaccionSeleccionada.DocumentLines" :key="item.id">
                          <td>{{ item.ItemDescription }}</td>
                          <td>{{ item.Quantity }}</td>
                          <td>{{ formatCurrency(item.Price) }}</td>
                          <td>{{ formatCurrency(item.LineTotal) }}</td>
                        </tr>
                        </tbody>
                      </v-simple-table>


                </div>
                <br>
                <br>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>


    <dialog-transacciones-facturar
    v-model="dialogFacturar"
    @input="dialog = $event"
    :trFacturar="trFacturar"
    />


  </v-container>
</template>

<script>
function title() {
  return "Transacciones";
}

import DialogTransaccionesFacturar from "./TransaccionesFacturar.vue";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import DialogFormCompleted from "@/views/dialogs/FormCompleted.vue";
import Loading from "@/views/components/Loading.vue";

export default {
  components: {
    DialogTransaccionesFacturar,
    Loading,
    DialogConfirmar,
    DialogFormCompleted,
    DialogTransaccionesFacturar
  },
  data: (vm) => ({
    title: title(),
    route: "transaccionesByUser",
    today: new Date().toISOString().substr(0, 10),
    search: "",
    users: false,
    snackbar: false,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    isLoading: false,
    loadingMsg:"obteniendo datos...",
    routeCancelOrders: 'cancelOrders',
    routeCancelDrafts: 'cancelDrafts',
    routeCancelInvoices: 'cancelInvoices',
    routeCancelInvoicesPws: 'cancelInvoicesPws',
    routeCancelPayments: 'cancelIncoimingPaymentsPws',
    headers: [
      { text: "Ref Pedido", filterable: true, value: "U_RefPedido", sortable: false ,class:"refpedido-header"},
      { text: "Estado", filterable: true, value: "estado", sortable: false ,class:"estado-header"},
      { text: "Cliente", filterable: true, value: "CardName", sortable: false },
      { text: "Ente", filterable: true, value: "ente", sortable: false },
      { text: "Total", filterable: true, value: "DocTotal", sortable: false },
      { text: "Métodos de pago", filterable: true, value: "U_MPago", sortable: false },
      { text: "Fecha", filterable: true, value: "DocDate", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },

    ],
    desserts: [],
    filters: {
      ref_pedido: "",
      ente: "",
      startDate: "",
      endDate: "",
      delegation: "",
    },
    filterKey: [],
    selectedHeaders: [],
    entidades: [
      {text: "ACARA", value: process.env.VUE_APP_DB_ACARA},
      {text: "MJ", value: process.env.VUE_APP_DB_MJ},
    ],
    startDateMenu: false,
    endDateMenu: false,
    maxDate:new Date().toISOString().substr(0, 10),
    loading: false,
    queryString:'',
    isAnAd:false,
    formSearch:{},
    filters: {
      ref_pedido: "",
      entidad: "",
      startDate: "",
      endDate: "",
      delegation: "",
    },
    entesDefault:[],
    startDate: null, // Fecha de inicio seleccionada
    endDate: null,   // Fecha de fin calculada
    dialogTS:false,

    transaccionSeleccionada :{
      DocumentLines:{}
    },
    dialogFacturar:false,
    trFacturar:{
      DocumentLines:{}
    },
    panelAbierto: 0,
    dialogs: {
      anularTransaccion: false,
      transaccionAnulada: false,
    },
    itemAnular:{},
  }),

  computed: {

    filteredData() {
      return this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
    formattedStartDate() {
      return this.startDate ? this.$formatDate(this.startDate) : "";
    },
    formattedEndDate() {

      return this.endDate ? this.$formatDate(this.endDate) : "";
    },
    formattedFechaInicio() {
      return this.transaccionSeleccionada.DocDate
        ? this.$formatDate(this.transaccionSeleccionada.DocDate)
        : "";
    },
  },

  watch: {
    startDate(newStartDate) {
      if (newStartDate) {
        const start = new Date(newStartDate);
        start.setDate(start.getDate() + 10);
        let fechaFinal = start.toISOString().slice(0, 10); // Formatea como 'YYYY-MM-DD'
        this.endDate = fechaFinal
        this.maxDate = fechaFinal;
      } else {
        this.endDate = null; // Si no hay fecha de inicio, limpia endDate
      }
    },
  },

  mounted() {
    console.log("Componente " + this.title + " creado");
  },

  async created() {
  try {
    // Verifica si sapProperties está vacío
    if (Object.values(this.$store.state.user.sapProperties).length === 0) {
      // Realiza la acción de inicio de sesión
      this.signIn();
      // Espera 3 segundos antes de proceder
      await this.$delay(3000);
    }

    // Llama a validateUserAnmacAduana y procesa la respuesta
    let response = await this.validateUserAnmacAduana();
    this.entesDefault = [process.env.VUE_APP_DB_MJ, process.env.VUE_APP_DB_ACARA];
    if (response) {
      this.isAnAd = response;
      // Asigna la delegación si está disponible
      this.formSearch.delegation = (this.$store.state.user.sapProperties.U_Deleg)?this.$store.state.user.sapProperties.U_Deleg:null;
      // Configura entidades si la respuesta es ANMAC
      if (response === 1) {
        this.entidades = [
          { text: "ANMAC", value: process.env.VUE_APP_DB_ANMAC },
        ];
        this.headers.splice(3, 1);
        this.entesDefault = [process.env.VUE_APP_DB_ANMAC,];
      }
    }

    // Configura encabezados seleccionados
    this.selectedHeaders = this.headers;
  } catch (error) {
    console.error("Error en created():", error);
  }
},

  methods: {
    anularTransaccion(item) {
      this.dialogs.anularTransaccion = true;
      this.itemAnular = item;
    },
    confirmAnularTransaccion() {


      let item = this.itemAnular;
      let route = '';
      let tipo = item.tipo;
      let params = item.DocEntry;

      let db = item.ente;
      switch(tipo){
        case 'invoices':
        if(db == this.$db("ANMAC")) {
          params = item.U_RefPedido;
          route =  (['BANKNACION', 'BTOB', 'SALDO'].includes(item.U_MPago) ? this.routeCancelInvoicesPws : this.routeCancelPayments) ;
        }
        else{
          route = this.routeCancelInvoices;
        }
        /*route = (db == this.$db("ANMAC"))
        ? (['BANKNACION', 'BTOB', 'SALDO'].includes(item.U_MPago)
            ? this.routeCancelInvoicesPws
            : this.routeCancelPayments)
        : this.routeCancelInvoices;*/


        break;
        case 'orders':
          route = this.routeCancelOrders;
        break;
        case 'drafts':
          route = this.routeCancelDrafts;
        break;
      }

      this.isLoading = true;
      this.loadingMsg = "Anulando Transacción";
      console.log("route");
      console.log(route);
      this.$axiosApi
        .postParams(route, params, {}, db)
        .then((r) => {
          //Swal.close();
          this.isLoading = false;

          if(r.data.code == 200){
            this.dialogs.transaccionAnulada = true;
            this.dialogs.anularTransaccion = false;
            //this.$initializeByQuery(false);
          }else{
            Swal.alertError('Error al Anular Transacción', r.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.alertError('Error al Anular Transacción', error);
          /*this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";*/
        });
        this.isLoading = false;
    },

        esMenorA24Horas(item) {

          let [year, month, day] = item.DocDate.split("-");
          let fechaIngresada = new Date(year, month - 1, day);
        //const fechaIngresada = new Date(item.DocDate);
        const ahora = new Date();

        // Calculamos la diferencia en milisegundos
        const diferencia = ahora - fechaIngresada;

        // 24 horas en milisegundos
        const veinticuatroHoras = 24 * 60 * 60 * 1000;

        return diferencia < veinticuatroHoras;
    },

    sePuedeFacturar(item){


      if(this.$canSap("venderElementosAnmac") && (item.estado =="Activo" || item.estado =="Vencido"))
      {
        return true;
      }
      else
      {
        return false;
      }


    },
    facturar(item)
    {

      try {
              //let refPedido = item.U_RefPedido;
              let ente = item.ente;
              let Tipo = item.tipo;
              let DocEntry = item.DocEntry;


              let ruta = this.$axiosApi.routes["transaccionDetalle"];

              // url = ruta.replace('{id}', refPedido); // Usamos refPedido en la URL base
              const url = ruta.replace('{id}', DocEntry); // Usamos refPedido en la URL base

              // Configuramos el ente como parámetro de consulta
              const queryParams = new URLSearchParams({ Ente: ente , Tipo: Tipo}).toString();

              const fullUrl = `${url}?${queryParams}`;


              Swal.alertGetInfo("Buscando información");

              this.$axiosApi.sendRequest(fullUrl, 'get')
                .then((r) => {
                  if (r.data.data) {
                    this.trFacturar = r.data.data;

                    this.trFacturar.PedidoDocTotal = this.trFacturar.DocTotal;

                    this.dialogFacturar = true;

                  }

                  //Swal.close();
                })
                .catch((error) => this.$handleAxiosError(error));


          } catch (error) {
            console.error('Error al obtener el detalle de la transacción:', error);

            Swal.close();

          }

    },
    viewFactura(item, tipo) {

      Swal.alertGetInfo("Buscando información");
      let id = item.DocEntry;
      let db = item.ente;
      let url = "/api/servicelayer/pdf/"+tipo+"/"+id+"/"+db;;





      this.$axiosApi
        .sendRequestPdfLoad(url, "GET")
        .then((response) => {
          Swal.close();
          //this.isLoading = false;
          if (response.data.size <= 0) {
            Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(console.error);
    },
    /*...mapActions({
      signIn: "login",
    }),
    ...mapActions("transacciones", ["fetchTransacciones", "selectTransaccion", "clearTransaccionDetalle"]),
    ...mapMutations("transacciones", ["setCurrentPage"]),
    */

    async goToDetalleInModal(item) {

      Swal.alertGetInfo("Obteniendo información <br><b></b>");



      try {
              //let refPedido = item.U_RefPedido;
              let ente = item.ente;
              let Tipo = item.tipo;
              let DocEntry = item.DocEntry;


              let ruta = this.$axiosApi.routes["transaccionDetalle"];

              // url = ruta.replace('{id}', refPedido); // Usamos refPedido en la URL base
              const url = ruta.replace('{id}', DocEntry); // Usamos refPedido en la URL base

              // Configuramos el ente como parámetro de consulta
              const queryParams = new URLSearchParams({ Ente: ente , Tipo: Tipo}).toString();

              const fullUrl = `${url}?${queryParams}`;


              Swal.alertGetInfo("Buscando información");

              this.$axiosApi.sendRequest(fullUrl, 'get')
                .then((r) => {
                  if (r.data.data) {
                    this.transaccionSeleccionada = r.data.data;

                    this.dialogTS = true;

                  }

                  Swal.close();
                })
                .catch((error) => this.$handleAxiosError(error));


          } catch (error) {
            console.error('Error al obtener el detalle de la transacción:', error);

            Swal.close();
            this.snackbar = true;
            this.text =
              "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
              error.data.code +
              " - <b>Error:</b> " +
              error.data.message +
              "</p>";
            this.color = "error";
          }






    },


    formatCurrency(value) {
      return '$' + this.$formatMoney(value);
    },

    async validateUserAnmacAduana() {
      const isAduanaAnmac = () => {
        if (this.$isEnabledProperty(this.$ANMAC)) {
          return 1;
        } else if (this.$isEnabledProperty(this.$ADUANA)) {
          return 2;
        } else {
          return false;
        }
      };

      let response = await isAduanaAnmac();

      return response;
    },
    fetchTransactions(){
      let queryParams = {
        CardCode: this.$store.state.user.username,
        skip: 0,
        Ente:'',
        delegation:''
      }


      queryParams.tipoUsuario = this.isAnAd ?  this.isAnAd : '0';

      if(this.isAnAd){
        if(this.formSearch.delegation) {
          queryParams.delegation = this.formSearch.delegation;
        }

      }


      queryParams.Ente = (this.formSearch.ente)?this.formSearch.ente:this.entesDefault.join(',');
      //Filtros

        if(this.formSearch.ref_pedido) {
          queryParams.U_RefPedido = this.formSearch.ref_pedido;
        } else {
          let fechaInicio  = this.startDate;
          let fechaFinal = this.endDate;
          //si no recibo como parametros, las fechas, tomo los ultimos 10 dias
          if(!(this.startDate && this.endDate)){
            fechaInicio = this.today;
            const start = new Date(this.today);
            start.setDate(start.getDate() - 10);
            fechaFinal = start.toISOString().slice(0, 10);
          }
          queryParams.DocDateFrom = fechaInicio || "";
          queryParams.DocDateTo = fechaFinal || "";
          queryParams.Ente = this.formSearch.ente && typeof this.formSearch.ente === 'string' && this.formSearch.ente.length > 0 ? this.formSearch.ente : this.entesDefault.join(',');
        }

      this.queryString = new URLSearchParams(queryParams).toString();

      console.log(this.queryString);
      this.$initializeByQuery(true);


    },
    filterByRef_pedido(item) {
      return this.$filterBy(item, "ref_pedido");
    },
    filterByEstado(item) {
      return this.$filterBy(item, "estado");
    },
    filterByTipo(item) {
      return this.$filterBy(item, "tipo");
    },
    filterByEnte(item) {
      return this.$filterBy(item, "ente");
    },

    filterByEndDate(item) {
      return this.$filterBy(item, "endDate");
    },
    filterByStartDate(item) {
      return this.$filterBy(item, "startDate");
    },
    filterByTotal(item) {
      return this.$filterBy(item, "total");
    },


    clearStartDate() {
      this.startDate = "";
    },
    clearEndDate() {
      this.endDate = "";
    },
    clearEntidad() {
      this.formSearch.ente = null;
    },
    clearField(field) {
      this.filters[field] = "";
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>

<style scoped>

.underline-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
