<template>
  <v-dialog v-model="dialogs['facturacion']"
      persistent
      fullscreen>
    <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar dark color="primary">
            <!--<v-btn icon dark @click="dialogs['facturacion'] = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>-->
            <v-toolbar-title>
               Facturar Transacción {{ trFacturar.U_RefPedido }}
            </v-toolbar-title>
            <v-spacer></v-spacer>


          </v-toolbar>

          <v-card-text  style="overflow-y: scroll; max-height: 80vh; min-height: 80vh">

                <v-row  >
                  <Loading  v-if="isLoading" :isLoading="isLoading" :loadingMsg="loadingMsg" />
                </v-row>
                <v-row >
                    <v-col cols="12" sm="6" class="px-0">
                      <v-row class="elevation-3 rounded  mt-0 ml-0 mr-0 ml-3" :class="!modificarOrden? 'py-8':''">
                        <v-col class="col-md-12 mt-1  py-0 text-left" >
                          <span style="font-weight: 600;">Documento: </span>
                          <span>{{  trFacturar.FederalTaxID}}</span>
                        </v-col>
                        <v-col class="col-md-12 py-0 mt-0 mb-2   text-left"  >
                          <span style="font-weight: 600;">Nombre: </span>
                          <span>{{  trFacturar.CardName}}</span>
                        </v-col>
                      </v-row>
                      <v-alert v-if="modificarOrden"
                        type="info"
                        outlined
                        dense
                        colored-border
                        color="info"
                        elevation="3"
                        class="mt-5 mx-0  ml-3 pa-0 pl-2"
                      >
                        <template v-slot:prepend >
                          <v-icon color="info" style="font-size: 30px"
                            >mdi-information</v-icon
                          >
                        </template>
                        <p
                          class="py-0 mt-4 ml-3 text-primary2"
                          style="font-weight: 400;"
                        >
                          Recuerde que el monto seleccionado debe coincidir con el pedido actual.
                        </p>
                      </v-alert>
                  </v-col>


                  <v-col cols="12" sm="3"  class="pt-3" >
                    <v-card class="ml-1">
                    <v-card-text>
                        <v-row>
                          <v-col cols="12" class="text-center py-8">
                            <h2 >{{ $formatARS(trFacturar.PedidoDocTotal) }}</h2>
                          </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions  style="color:#fff" class="success ">
                        <v-row>
                          <v-col cols="12" class="pb-1 py-0 text-center">
                              PEDIDO
                          </v-col>
                        </v-row>

                    </v-card-actions>


                </v-card >
                  </v-col>
                  <v-col cols="12" sm="3" class="pl-1 pt-3" >
                    <v-card class="rounded">
                        <v-card-text>
                            <v-row>
                              <v-col cols="12" class="text-center py-8">
                                <h2 >{{ $formatARS(trFacturar.DocTotal) }}</h2>
                              </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-actions  style="color:#fff" class="primary ">
                            <v-row>
                              <v-col cols="12" class="pb-1 py-0 text-center">
                                  TOTAL
                              </v-col>
                            </v-row>

                        </v-card-actions>


                    </v-card>
                  </v-col>
              </v-row>





                <v-row v-if="modificarOrden" class="elevation-3 mx-0 pt-1 pb-0 mt-3 mb-0 rounded-lg">
                  <v-col  class="col-xs-12">
                  <!--grilla de elementos-->
                  <v-data-table
                        :headers="headers"
                        :items="desserts"
                        :search="search"
                        sort-by="secret"
                        class="elevation-0 data__table"
                        :footer-props="{
                          'items-per-page-options': [10, 20],
                        }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat color="white" class="pa-0 pr-0">
                            <v-row class="pa-0 pt-4 ml-0">


                              <v-col sm="3" class="px-0 ml-0 text-end">
                                <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Buscador"
                                  dense
                                  filled
                                  rounded
                                  solo
                                ></v-text-field>
                              </v-col>
                              <!--
                              <v-col sm="8" class="pa-0 pt-2 ml-0 text-end">
                                <span class="text--primary-cart"
                                  >${{ $formatMoney($store.state.carrito.total) }}</span
                                >
                                <v-menu
                                  bottom
                                  right
                                  offset-y
                                  origin="top right"
                                  transition="scale-transition"
                                  :close-on-content-click="false"
                                >
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                      class="ml-2"
                                      min-width="0"
                                      text
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="force()"
                                    >
                                      <v-badge
                                        v-if="$store.state.carrito.cantItem > 0"
                                        color="primary"
                                        overlap
                                        bordered
                                      >
                                        <template v-slot:badge>
                                          <span>{{ $store.state.carrito.cantItem }}</span>
                                        </template>

                                        <v-icon class="icon-25">mdi-cart</v-icon>
                                      </v-badge>
                                      <v-badge v-else overlap bordered>
                                        <template v-slot:badge>
                                          <span>0</span>
                                        </template>

                                        <v-icon class="icon-25">mdi-cart</v-icon>
                                      </v-badge>
                                    </v-btn>
                                  </template>
                                  <v-list
                                    :tile="false"
                                    class="pa-0 ma-0"
                                    nav
                                    dense
                                    style="width: 400px"
                                  >
                                    <Carrito
                                      :key="keyCarrito"
                                      :merge-elementos="mergeElementoConCarrito"
                                    />
                                  </v-list>
                                </v-menu>
                              </v-col>-->
                            </v-row>
                          </v-toolbar>





                        </template>

                        <template v-slot:header.unidades="{ header }">
                          <div class="d-flex align-center">
                            <span>{{ header.text }}</span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  class="ml-2"
                                  color="default"
                                  style="
                                    height: 18px;
                                    width: 18px;
                                    font-size: 10px;
                                    background-color: black;
                                  "
                                >
                                  <i class="fa fa-info" aria-hidden="true"></i>
                                </v-btn>
                              </template>
                              <span>La cantidad debe ser multiplo de la cantidad mínima</span>
                            </v-tooltip>
                          </div>
                        </template>

                        <template v-slot:[`item.ForeignName`]="{ item }">
                          <v-row>
                            <v-col class="col-md-12 pt-4 pb-0 row-producto-item-name">
                              {{ item.ForeignName | capitalizeFirst }}</v-col
                            >
                          </v-row>
                          <v-row class="pt-2 mb-1">
                            <v-col class="col-md-6 pa-0 px-3" style="margin-top: -10px">
                              <v-row class="pt-2 mb-1">
                                <v-col class="col-md-2 pa-0 px-0 ml-3" style="">
                                  <span class="row-producto-item-code">{{
                                    item.ItemCode
                                  }}</span>
                                </v-col>

                                <v-col class="col-md-6 pa-0 mx-0" style="">
                                  <span
                                    class="custom-badge"
                                    :class="{
                                      'badge-blue': item.db.substring(4, 6) === 'AC',
                                      'badge-green': item.db.substring(4, 6) === 'MJ',
                                      'badge-grey': item.db.substring(4, 6) === 'AN',
                                    }"
                                  >
                                    {{ item.db.substring(4, 6) }}</span
                                  >
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:[`item.priceList`]="{ item }">
                          <v-row class="text-start">
                            <v-col class="col-md-12 pt-2 pb-0"
                              ><b>{{
                                item.ItemPrices[0].Price != ""
                                  ? "$ " + $formatMoney(/*item.priceList.Price*/item.ItemPrices[0].Price)
                                  : "&nbsp;"
                              }}</b></v-col
                            >
                            <v-col class="col-md-12 pa-0 px-2" style="margin-top: -10px">
                              <span class="row-unidades-compra-min"
                                >Cantidad de Stock.
                                {{ Number(item.InStock) }}</span
                              ></v-col
                            >
                          </v-row>
                        </template>

                        <template v-slot:[`item.unidades`]="{ item }">
                          <div class="d-flex align-center">
                            <v-btn
                              x-small
                              class="btn-decrement btn-mas-menos"
                              :class="item.error != '' && item.error ? 'bg-error' : ''"
                              @click="decrement(item)"
                            >
                              <v-icon style="font-size: 16px">mdi-minus</v-icon>
                            </v-btn>


                            <v-text-field
                              v-model.number="item.unidades"
                              type="number"
                              @keydown="$validarSoloNumeros"
                              class="row-unidades center-number-input no-border"
                              hide-details
                              height="25"
                              :error-messages="item.error"
                              @input="$validarCantStock(item);"
                              :class="item.error != '' && item.error ? 'border-error' : ''"
                            ></v-text-field>

                            <v-btn
                              x-small
                              class="btn-increment btn-mas-menos"
                              @click="increment(item)"
                              :class="item.error != '' && item.error ? 'bg-error' : ''"
                            >
                              <v-icon style="font-size: 16px">mdi-plus</v-icon>
                            </v-btn>
                            <v-tooltip
                              bottom
                              v-if="item.error != '' && item.error"
                              color="error"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  class="ml-2 error"
                                  style="height: 18px; width: 18px; font-size: 10px"
                                >
                                  <v-icon style="font-size: 13px">mdi-close</v-icon>
                                </v-btn>
                              </template>

                              <span class="white--text text--small">{{ item.error }}</span>
                            </v-tooltip>
                          </div>

                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            small
                            rounded

                            :class="
                              item.disabled
                                ? ''
                                : item.buttonClass != ''
                                ? item.buttonClass
                                : 'btn-primary'
                            "
                            class="px-3 capitalize-first custom-btn"
                            :disabled="item.disabled || item.disabled == null"
                            @click="agregarEstampilla(item)"
                          >
                            <small
                              :class="
                                item.buttonClass != '' || !item.disabled
                                  ? 'capitalize-first text-button-carrito'
                                  : 'capitalize-first text-button-carrito-vacio'
                              "
                            >
                              {{ item.buttonTxt }}
                            </small>
                          </v-btn>

                          <v-btn v-if="item.buttonClass != '' && !item.disabled "
                            small
                            rounded
                            outlined
                            class="px-0 mx-0"

                            @click="eliminarEstampilla(item)"
                          >
                            <small
                              :class="
                                item.buttonClass != '' || !item.disabled
                                  ? 'capitalize-first text-button-carrito'
                                  : 'capitalize-first text-button-carrito-vacio'
                              "
                            ><v-icon style="font-size: 16px;color: #de0000;">mdi-delete</v-icon>
                            </small>
                          </v-btn>

                        </template>
                      </v-data-table>

                  <!--grilla de elementos-->
                  </v-col>
                </v-row>

                <v-simple-table v-else class="elevation-1 styled-table mt-6">
                  <thead>
                  <tr>
                    <th>Descrpición</th>
                    <th>Cantidad</th>
                    <th>Precio</th>
                    <th>Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="itemOrden in trFacturar.DocumentLines" :key="itemOrden.id">
                    <td>{{ itemOrden.ItemDescription }}</td>
                    <td>{{ itemOrden.Quantity }}</td>
                    <td>{{ formatCurrency(itemOrden.Price) }}</td>
                    <td>{{ formatCurrency(itemOrden.LineTotal) }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>



                <br>
                <br>
          </v-card-text>
          <v-card-actions class="py-2 " style="border-top:#ddd solid 1px">
                <v-row>
                  <v-col class="text-end">
                    <v-btn width="120" color="white" @click="closeDialog" class="capitalize-first custom-btn">
                      Cerrar
                    </v-btn>
                    <v-btn width="120" color="primary" dark @click="validateFacturar"  class="capitalize-first custom-btn">
                      Facturar venta
                    </v-btn>
                </v-col>
                </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
      <dialog-validate-facturacion
                v-model="dialogs['validateStock']"
                @input="dialogs['validateStock'] = $event"
                title="Stock Insuficiente"
                textButton="Modificar orden"
                bodyText="No hay suficientes unidades disponibles. Modifica el  Pedido o seleccione otra estampilla.<br><br><p style='color:#FB8C00;font-size:0.9rem'>Recuerde que el monto seleccionado debe coincidir con el pedido actual.</p>"
                icon="mdi-alert-circle-outline"
                color="#FB8C00"
                @close="modificarItemsOrden"

              />
       <dialog-validate-importe
                v-model="dialogs['validateImporte']"
                @input="dialog = $event"
                title="Montos no coinciden"

                bodyText="El monto de la venta no coincide con el monto de la orden de venta. Para continuar, asegúrate de que ambos montos sean iguales."
                icon="mdi-alert-circle-outline"
                color="#FB8C00"
                textButton="Reintentar"
              />
        <dialog-validate-facturacion
                v-model="dialogs['confirmarFacturacion']"
                @input="dialogs['confirmarFacturacion'] = $event"
                title="¿Estás seguro de que desea generar las facturas?"
                bodyText="Se generará la factura por la venta de estampillas"


                @close="postInvoice"

              />



  </v-dialog>

</template>

<style scoped>
.custom-btn {
  text-transform: none; /* Elimina la transformación de mayúsculas */
  font-family: inherit; /* Hereda el tipo de letra por defecto */
}

.capitalize-first::first-letter {
  text-transform: capitalize; /* Solo la primera letra en mayúscula */
}
</style>
<script>

import DialogValidateFacturacion from "@/views/dialogs/FormConfirmar.vue";
import DialogValidateImporte from "@/views/dialogs/Reintentar.vue";
import Loading from "@/views/components/Loading.vue";
import { setTimeout } from "core-js";


export default {
   components: {
    DialogValidateFacturacion,
    DialogValidateImporte,
    Loading,

  },
  props: {

    trFacturar:{
      type:Object,
      required:true
    },

    value: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      keyCarrito: 0,
      routeFactura: "facturarVenta",
      search: "",
      route: "elementosAnmacByWarehouse",
      isLoading: false,
      loadingMsg:"obteniendo datos...",
      headers:  [
          {
            text: "Codigo",
            filterable: true,
            value: "ItemCode",
            align: " d-none",
          },
          {
            text: "Producto",
            filterable: true,
            value: "ForeignName",
            width: "45%",
          },
          { text: "P. Unitario", filterable: true, value: "priceList" },
          {
            text: "Unidades",
            filterable: true,
            value: "unidades",
            sortable: false,
            width: "25%",
          },
          { text: "", value: "actions", sortable: false ,class:"header-actions-custom"},
        ],
      desserts: [],

      modificarOrden:false,

      dialogs:{
        facturacion:false,
        validateStock:false,
        validateImporte:false,
        confirmarFacturacion:false
      },
      stockSuficiente :true
    };
  },
  watch: {
    value(val) {
      this.dialogs.facturacion = val;


      if(this.dialogs.facturacion)
      {

        this.getStockEstampillas();
        ////////para desarrollar
       // this.modificarItemsOrden({confirmed:true});
      }

    },
     'dialogs.facturacion'(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    async modificarItemsOrden(event) {

      if (event.confirmed) {

        //consultar elementos y cargar grilla
        //this.getStockEstampillas();
        Swal.alertGetInfo("Buscando información");
        setTimeout(() => {
          Swal.close();
          this.modificarOrden = true;
        }, 400);

      }
    },
    async postInvoice(event) {

      if (event.confirmed) {

        //Swal.fire("Aca va el invoice");
        //console.log(this.trFacturar);
        this.isLoading = true;
        this.loadingMsg = "Generando Facturas...";

        // Llamamos al método storeFactura y esperamos la respuesta
        const responseFactura = await this.storeFactura();

        if (responseFactura.data.code == 200) {
          this.isLoading = false;
          let dataFacturas = responseFactura.data.data;
          if(dataFacturas.length > 0){
            this.$router.push({
              name: "carrito_detalle_facturacion",
              params: { facturas: dataFacturas },
            });
            this.$store.commit("carrito/remove");
            this.$store.commit("carrito/removeCliente");
          }
          else{

            this.dialogs.validateStock = true;
            this.messageStock = responseFactura.data.message;
          }

        }
        else
        {
          this.isLoading = false;
          let message=  responseFactura.messages ?? "";
          Swal.fire({
              icon: "error",
              title: "No se generaron los pedidos correspondientes",
              html: message,
            });
        }

      }
    },

    async storeFactura() {
      try {
        let formUserData = {
          whsVenta: this.$store.state.user.sapProperties.delegInfo.U_WhsDisp, //almacen de ventas
          poi: this.$store.state.user.sapProperties.delegInfo.U_POI //point of issue code
        };
        let form = {
          userData: formUserData,
          venta: this.trFacturar,
        };

        console.log(form);

        //verificar en el back el metodo ordenes de servicelayer para venta anmac
        const response = await this.$axiosApi.post(this.routeFactura, form);

        if (response.status != 200) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response;
        console.log(data);
        return data;
      } catch (error) {
        console.error("Error en la conexión con la API:", error);
        return error;

      }
    },

    closeDialog() {
      this.modificarOrden=false;
      this.dialogs.facturacion = false;
    },

    validateFacturar() {



      //console.log(this.trFacturar.DocumentLines);
      //comparo unidades de la orden contra los del stock para saber si se puede facturar
      this.trFacturar.DocumentLines.forEach((item) => {
//console.log(item);
        let dessertItem = this.desserts.find( (dessert) =>  dessert.ItemCode ===item.ItemCode );
//console.log(item.unidades, dessertItem.InStock, this.stockSuficiente);
        //if(item.Quantity > dessertItem.InStock && this.stockSuficiente)
        if(item.unidades > dessertItem.InStock)
        {
            this.stockSuficiente = false;            
        }
        else
        {
          this.stockSuficiente = true;
        }

      });


      if(!this.stockSuficiente)//no hay stock
      {
        this.dialogs.validateStock =true;
      }
      else if(this.trFacturar.DocTotal != this.trFacturar.PedidoDocTotal){//importes son distintos

            this.dialogs.validateImporte =true
      }
      else
      {
        //confirmacion de registro de facturas
        this.dialogs.confirmarFacturacion =true;

      }

    },




    async getStockEstampillas() {





      /********************************************************************* */
      let url = 'api/hanaxs/bussinesspartners/clientes/search/'+process.env.VUE_APP_DB_ANMAC;

      const params = [];
      if (this.trFacturar.FederalTaxID) {
        params.push(`documento=${encodeURIComponent(this.trFacturar.FederalTaxID)}`);
      }

      // Agregar los parámetros a la URL
      if (params.length) {
        url += `?${params.join("&")}`;
      }

      let clienteOrden =[];
      await this.$axiosApi
        .sendRequest(url, "get")
        .then((response) => {
          if (response.data && response.data.data) {
            clienteOrden = response.data.data.map((cliente) => {
              const firstAddress = cliente.BPAddresses?.[0] || {};
              return {
                ...cliente,
                City: firstAddress.City || "null"
              };
            });
          } else {
            clienteOrden = [];
          }

        })
        .catch((error) => {
          console.error("Error al buscar los datos del cliente:", error);
          Swal.close();
          Swal.fire("Error", "No se pudieron obtener los datos. Intenta nuevamente.", "error");
        });

        /********************************************************************* */

      let formSap = {};
      const trueProps = Object.entries(clienteOrden)
        .filter(([key, value]) => (value === true || value === 'tYES'))
        .map(([key]) => key);

      trueProps.push(clienteOrden.PriceListNum);

      formSap.userProps = trueProps;

      let warehouse = this.$store.state.user.sapProperties.delegInfo.U_WhsDisp;
      await this.$axiosApi
        .postParams(this.route, warehouse, formSap)
        .then((r) => {
          if (r.data.data) {
            //console.log(r.data.data);
            this.desserts = r.data.data;


            this.mergeEstampillasStockOrden();
            Swal.close();
          }


        })
        .catch(function (error) {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          //Swal.close();
        });
    },

    async mergeEstampillasStockOrden() {
      //let ordenItems = this.$store.state.carrito.data; // El array del carrito

      let ordenItems = this.trFacturar.DocumentLines;
      this.desserts.forEach((dessert) => {
        // Encontrar si el ItemCode del dessert está en el carrito
        let ordenItem = ordenItems.find( (item) => item.ItemCode === dessert.ItemCode );

        if (ordenItem) {

          let errorStockMessage ="";

          console.log(ordenItem.Quantity , dessert.InStock );

          if( ordenItem.Quantity > dessert.InStock )//aca valido el stock
          {
            errorStockMessage ="No hay Stock";
          }
          ordenItem.unidades =ordenItem.Quantity;
          dessert.unidades = ordenItem.Quantity;
          dessert.error = errorStockMessage;
          dessert.disabled = false;
          dessert.buttonTxt = "Agregado al carrito";
          dessert.buttonClass = "btn-success";
        } else {
          dessert.unidades = "";
          dessert.error = "";
          dessert.disabled = true;
          dessert.buttonTxt = "Agregar al carrito";
          dessert.buttonClass = "";
        }

        dessert.TipoElementosId = 5;
        dessert.TipoElementosDesc = "Elementos ANMaC";

      });
    },




    agregarEstampilla(item) {

      if (item.unidades > 0) {


        item.buttonClass = "btn-success";
        item.buttonTxt = "Agregado al carrito";
        item.error = "";
        //console.log(item);
        let index = this.trFacturar.DocumentLines.findIndex(p => p.ItemCode === item.ItemCode);
        //console.log(index);
        if (index !== -1) {
          this.trFacturar.DocumentLines[index].unidades = item.unidades;
          this.trFacturar.DocumentLines[index].Price = item.ItemPrices[0].Price
        } else {
          item.unidades = item.unidades;
          item.Price = item.ItemPrices[0].Price
          this.trFacturar.DocumentLines.push(item);
        }



      } else {
        Swal.alert(
          "No es posible agregar el producto",
          "Para agregar el producto tenes que ingresar la cantidad de unidades"
        );
      }

      this.calcularTotalPedido();

      console.log(this.trFacturar.DocumentLines);
    },
    eliminarEstampilla(item) {


      if (item.unidades > 0) {

        item.disabled = true;
        item.buttonClass = "";
        item.buttonTxt ="Agregar al carrito";
        item.error = "";
        item.unidades = "";

        let index = this.trFacturar.DocumentLines.findIndex(p => p.ItemCode === item.ItemCode);

        if (index !== -1) {
          this.trFacturar.DocumentLines.splice(index, 1);
        }

      } else {
        Swal.alert(
          "No es posible agregar el producto",
          "Para agregar el producto tenes que ingresar la cantidad de unidades"
        );
      }

      this.calcularTotalPedido();
    },

    calcularTotalPedido(){

      let pedidoTotal =0;

      this.trFacturar.DocumentLines.forEach((item) => {

      if(item.unidades != "")
          pedidoTotal += (item.unidades * parseInt(item.Price));
      });
      this.trFacturar.PedidoDocTotal =pedidoTotal;

      this.$forceUpdate();

    },

    async increment(item) {


      let respStoc = await this.$validarCantStock(item, 'i');

      if (respStoc == false) {
        return;
      }

      let divisor = Number(item.unidades) / Number(item.SalesQtyPerPackUnit);

      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        let NuevasUnidades = Math.ceil( item.unidades / Number(this.desserts[index].SalesQtyPerPackUnit) ) * Number(this.desserts[index].SalesQtyPerPackUnit);
        if (NuevasUnidades == item.unidades) {
          NuevasUnidades =
            Number(item.unidades) +
            Number(this.desserts[index].SalesQtyPerPackUnit);
        }

        this.desserts[index].unidades = NuevasUnidades;

        this.desserts[index].disabled = false;
        this.desserts[index].buttonTxt = "Agregar al carrito";
        this.desserts[index].buttonClass = "";
        this.desserts[index].error = "";


        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }
  },
    async decrement(item) {


      let respStoc = await this.$validarCantStock(item);


      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        if (  this.desserts[index].unidades -  Number(this.desserts[index].SalesQtyPerPackUnit) >  0 ) {

          let NuevasUnidades =
            Math.floor(
              item.unidades / Number(this.desserts[index].SalesQtyPerPackUnit)
            ) * Number(this.desserts[index].SalesQtyPerPackUnit);

          if (NuevasUnidades == item.unidades) {
            NuevasUnidades =
              Number(item.unidades) -
              Number(this.desserts[index].SalesQtyPerPackUnit);
          }

          if(respStoc){
            this.desserts[index].unidades = NuevasUnidades;
          }
          else{
            this.desserts[index].unidades = item.InStock;
          }
          this.desserts[index].disabled = false;
          this.desserts[index].buttonTxt = "Agregar al carrito";
          this.desserts[index].buttonClass = "";
          this.desserts[index].error = "";

        } else {
          this.desserts[index].unidades = 0;

          this.desserts[index].disabled = true;
          this.desserts[index].buttonTxt = "Agregar al carrito";
          //this.desserts[index].buttonClass = '';

          this.desserts[index].error =
            "El valor ingresado debe ser mayor que 0";
        }

        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }
    },
    formatCurrency(value) {
          return '$' + this.$formatMoney(value);
    },
  },
  computed:{
    formattedFechaInicio() {
      return this.trFacturar.DocDate
        ? this.$formatDate(this.trFacturar.DocDate)
        : "";
    },

  }
};
</script>
